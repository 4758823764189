<template>
  <div id="policy-container">
    <div class="title">Sistem Dilleri</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Sistem Dilleri</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
      </div>
    </div>


    <div class="all-data">
      <div class="list-table">
        <el-table :data="allLanguages" style="width: 100%">
          <el-table-column label="Dil Adı" width="200">
            <template slot-scope="scope"> {{ scope.row.Title }} </template>
          </el-table-column>
          <el-table-column label="Dil Kodu" width="100">
            <template slot-scope="scope"> {{ scope.row.LanguageCode }} </template>
          </el-table-column>
          <el-table-column label="Dil Sistem Kodu" width="200">
            <template slot-scope="scope"> {{ scope.row.LanguageSystemCode }} </template>
          </el-table-column>
          <el-table-column label="Sıra Numarası" width="200">
            <template slot-scope="scope"> {{ scope.row.OrderNo }} </template>
          </el-table-column>
          <el-table-column label="Durum" width="300" align="start">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.IsActive" @change="setActivity(scope.row)" active-text="Aktif"
                inactive-text="Pasif" active-color="#234A98"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Fotoğraf" width="100">
            <template slot-scope="scope"> <img :src="scope.row.Image" height="40" width="40" alt="" /> </template>
          </el-table-column>
          <el-table-column width="300" align="center">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row.ID)">Düzenle</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="deleteSpecialities(scope.row.ID)">Sil</span></el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Dil bulunamadı.</div>
              <div class="no-data-text">Herhangi bir dil bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false">
        <div class="title">{{ form.Id == null ? "Dil Ekle" : "Dil Güncelle" }}</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="input-container">
            <label>Dil Adı</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Dil Kodu</label>
            <el-input v-model="form.LanguageCode"></el-input>
            <span class="error">{{ $errorMessage("LanguageCode", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Dil Sistem Kodu</label>
            <el-input v-model="form.LanguageSystemCode"></el-input>
            <span class="error">{{ $errorMessage("LanguageSystemCode", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Sıra No</label>
            <el-input-number v-model="form.OrderNo"></el-input-number>
            <span class="error">{{ $errorMessage("OrderNo", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Durum</label>
            <el-switch v-model="form.IsActive" active-text="Aktif" inactive-text="Pasif" active-color="#234A98">
            </el-switch>
            <span class="error">{{ $errorMessage("IsActive", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Fotoğraf</label>
            <el-upload class="el-up" :action="$client.defaults.baseURL + 'Account/UploadFile?&type=3'" :headers="{
              Authorization: $client.defaults.headers.common['Authorization'],
            }" :show-file-list="false" :on-success="fileSuccess">
              <el-button size="small" style="justify-self: flex-start" type="primary" v-if="form.Image == null">
                <i class="icon-plus"></i>
                Fotoğraf Ekle</el-button>
            </el-upload>
            <div v-if="form.Image != null">
              <img :src="form.Image" height="40" width="40" alt="" />
            </div>
            <el-button class="clr-btn" @click="form.Image = null" size="small" style="justify-self: flex-start"
              type="primary" v-if="form.Image != null"> Fotoğrafı Sil</el-button>
            <span class="error">{{ $errorMessage("IsActive", ValidationErrors) }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addUpdateSegmentation()"><i
                class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Id: null,
        Title: null,
        LanguageCode: null,
        LanguageSystemCode: null,
        OrderNo: null,
        IsActive: true,
        Image: null,
      },
      addUpdateDialog: false,
      onSave: false,
      ValidationErrors: [],
      allLanguages: [],
    };
  },

  async beforeMount() {
    await this.getData();
  },

  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    fileSuccess(res) {
      //Hata mesajı çıkması için if else eklendi HS !!! 19/05/2023
      if (res.HasError != undefined && res.HasError == true) this.$message.error(res.Message);
      else this.form.Image = res.Item.Url;
    },
    async getData() {
      var res = await this.$client.post("/Localization/SystemLanguagesAdmin");
      this.allLanguages = res.data.Data.Items;
    },
    async addUpdateSegmentation() {
      this.ValidationErrors = [];
      // this.onSave = true;
      var res = await this.$client.post("/Localization/AddUpdateSystemLanguage", this.form);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    async deleteSpecialities(id) {
      var res = await this.$client.post("/Segment/Delete", { ID: id });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    setItem(id) {
      this.ValidationErrors = [];

      if (id == null) {
        this.form.Id = null;
        this.form.Title = null;
        this.LanguageCode = null;
        this.LanguageSystemCode = null;
        this.OrderNo = null;
        this.IsActive = true;
        this.Image = null;
      } else {
        var item = this.allLanguages.find((x) => x.ID == id);
        this.form.Id = item.ID;
        this.form.Title = item.Title;
        this.form.LanguageCode = item.LanguageCode;
        this.form.LanguageSystemCode = item.LanguageSystemCode;
        this.form.OrderNo = item.OrderNo;
        this.form.IsActive = item.IsActive;
        this.form.Image = item.Image;
      }
      this.addUpdateDialog = true;
    },

    async setActivity(item) {
      var res = await this.$client.post("/Localization/AddUpdateSystemLanguage", item);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },

    exportKeys() {
      var label = "DilAnahtarları.xlsx";
      this.$client
        .get("/Localization/Export", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    fileSuccessKey() {
      this.$message.success("Dosya başarıyla yüklendi");
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234A98;

        i {
          background: white;
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        th:first-child {
          padding-left: 15px;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 15px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            i {
              height: 40px;
            }
          }
        }
      }
    }

    .el-dialog {
      width: 50%;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;

      // .el-dialog__body {
      //   padding-bottom: 0;
      //   margin-top: 18px;
      // }
      .el-icon-close:before {
        content: "";
      }

      .el-dialog__header {
        display: none;
      }

      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }

        .el-select {
          width: 100%;
          border-color: #8697a8;
        }

        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-active>.ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }
      }

      .dialog-footer {
        width: 100%;

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .btn-add {
            filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));

            width: 152px;
            background: #234A98;
            height: 40px;
            padding: 0;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: white;
            border: none;

            i {
              float: left;
            }
          }

          .btn-cancel {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            width: 110px;
            color: #234A98;
            background-color: #eae5f8;
            border: none;
          }
        }
      }
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .el-radio-group {
      margin-bottom: 10px;
      margin-top: 10px;

      .el-radio {
        .el-radio__label {
          color: #234A98;
        }

        .is-checked {
          .el-radio__inner {
            border-color: #234A98;
            background-color: #234A98;
          }
        }

        .el-radio__input {
          .el-radio__inner {
            border-color: #234A98;
          }
        }
      }
    }
  }

  .el-switch__label.is-active {
    color: #234A98;
  }

  .main-price {
    margin-bottom: 24px;

    .price {
      display: flex;
      gap: 30px;
      align-items: center;

      .main-price-title {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
      }
    }

    .main-price-input {
      width: 200px;
    }

    .save-price-btn {
      background-color: #234A98 !important;
      filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
      border: none;
      color: white;

      span {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;

        i {
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  .clr-btn {
    background-color: #234A98 !important;
    border: none;
    color: white;

    span {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;

      i {
        height: 14px;
        width: 14px;
      }
    }
  }

  .keys {
    .export-btn {
      background-color: #ff3d57 !important;
      border: none;
      color: white;

      span {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;

        i {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}</style>
